import React from 'react';

import styles from './Description.module.css';

interface Props {
    readonly description: string;
    readonly disabled?: boolean;
    readonly externalStyles?: string;
}

export default function Description({
    description,
    disabled = false,
    externalStyles = 'ml-8',
}: Props) {
    // Using a span for description doesn't honor line heights
    // and stretches multiline strings out vertically
    return (
        description && <span className={`${styles.description} ${disabled && 'text-lightGray'} ${externalStyles}`}>{description}</span>
    );
}
