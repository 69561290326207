// 'primary': {
//     light: '#FFBB91', // Coral
//     DEFAULT: '#FF7E67', // Salmon
// },
// 'secondary': {
//     light: '#68B0AB', // Robin Egg
//     DEFAULT: '#006A71', // Pine Green
// },
// 'disabled': {
//     light: '#F2DEBB',
//     DEFAULT: '#EFA619', // Honey
//     dark: '#BCAD96',
// },
// rgba(239, 166, 25, .4) => #EFA61766 (~colors.disabled.DEFAULT)
// rgba(104, 176, 171, var(--tw-ring-opacity)) => colors.secondary.light
// rgba(0, 106, 113, var(--tw-border-opacity)) => colors.secondary.DEFAULT

export const Styles = (ref, error) => ({
    input: (styles) => ({
        ...styles,
        '* :focus': {
            '--tw-ring-color': '0 !important',
            '--tw-ring-opacity': '0 !important',
        },
    }),
    control: (styles, { isFocused, isDisabled }) => {
        let borderColor;
        let bgColor;
        if (isDisabled) {
            borderColor = 'var(--color-yellow)'; // colors.disabled.dark
            bgColor = 'var(--color-yellow)'; // colors.disabled.light
        } else if (error) {
            // -----------------------------------------------------------------
            // The following attempts to emulate the following combination of Tailwind classes:
            // * focus:border-red-600 (`rgba(220, 38, 38, var(--tw-border-opacity))`; opacity=1)
            // -----------------------------------------------------------------
            borderColor = 'rgba(220, 38, 38, var(--tw-border-opacity))';
            bgColor = 'white';
        } else {
            borderColor = 'rgba(0, 106, 113, var(--tw-border-opacity))'; // colors.secondary.DEFAULT
            bgColor = 'white';
        }

        let boxShadow;
        if (error) {
            if (isFocused) {
                // -----------------------------------------------------------------
                // The following attempts to emulate the following combination of Tailwind classes:
                // * focus:ring-2
                // * focus:ring-red-200 (`rgba(254, 202, 202, var(--tw-ring-opacity))`; opacity=1)
                // -----------------------------------------------------------------
                boxShadow = 'white 0px 0px 0px 0px, rgba(254, 202, 202, var(--tw-border-opacity)) '
                    + '0px 0px 0px 2px, black 0px 0px 0px 0px';
            } else {
                boxShadow = 'none';
            }
        } else if (isFocused) {
            // -----------------------------------------------------------------
            // The following attempts to emulate the following combination of Tailwind classes:
            // * focus:ring-2
            // * focus:ring-lightGray
            // * focus:border-secondary
            // * border-secondary
            // -----------------------------------------------------------------
            boxShadow = 'white 0px 0px 0px 0px, var(--color-green) 0px 0px 0px 2px, black 0px 0px 0px 0px'; // colors.secondary.light
        } else {
            boxShadow = 'none';
        }

        return {
            ...styles,
            backgroundColor: bgColor,
            ':hover': {
                // Workaround to disable hover behavior.
                borderColor,
            },

            boxShadow,

            '--tw-border-opacity': 1,
            borderColor,
            borderRadius: '16px',
            minHeight: 32,
        };
    },
    option: (styles, { isFocused, options, value }) => {
        const propsRef = ref().props;
        let selectedOptions = propsRef.value;
        if (!(selectedOptions instanceof Array)) {
            selectedOptions = [selectedOptions];
        }

        const isLastElement = options && options.length
            && value === options[options.length - 1].value;
        return {
            ...styles,
            backgroundColor: isFocused ? 'var(--color-yellow)' : 'white', // colors.primary.DEFAULT
            ':active': {
                ...styles[':active'],
                backgroundColor: 'var(--color-progressBarBlue)', // colors.secondary.DEFAULT
            },
            borderBottom: !isLastElement && '1px solid var(--color-progressBarBlue)', // colors.secondary.DEFAULT
            color: 'var(--color-cleanSlate)',
        };
    },
    placeholder: (styles, { isDisabled }) => ({
        ...styles,
        color: isDisabled ? 'var(--color-orange)' : 'var(--color-gray)', // colors.disabled.DEFAULT
    }),
    menu: (styles) => ({ ...styles, backgroundColor: 'white' }),
    menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
    multiValue: (styles, { isDisabled }) => ({
        ...styles,
        borderRadius: '20px',
        backgroundColor: isDisabled ? 'var(--color-orange)' : 'var(--color-yellow)', // colors.primary.DEFAULT
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        color: 'black',
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: 'black',
        ':hover': {
            backgroundColor: 'text-cleanSlate', // colors.primary.DEFAULT
        },
    }),
    dropdownIndicator: () => ({
        padding: 0,
        paddingRight: 8,
    }),
    indicatorSeparator: () => ({ display: 'none', padding: 0, margin: 0 }),
    clearIndicator: (styles) => ({
        ...styles,
        color: 'var(--color-cleanSlate)', // colors.primary.DEFAULT
        ':hover': {
            color: 'var(--color-yellow)', // colors.primary.DEFAULT
        },
        padding: 0,
        margin: 0,
    }),
    valueContainer: () => ({
        padding: '0 8px',
        margin: 0,
        height: 32,
        flexWrap: 'nowrap!important',
    }),
});

export const ReactSelectClassNames = (error) => ({
    control: (state) => `min-h-[32px] border ${error ? 'border-red-500' : 'border-gray-300'} bg-gray-50 rounded-[8px] ${state.isFocused && 'ring-2 ring-lightGray'}`,
    dropdownIndicator: () => 'p-0 pr-2',
    clearIndicator: () => 'p-0 px-2 text-cleanSlate',
    indicatorSeparator: () => 'hidden',
    valueContainer: () => 'flex-wrap md:flex-nowrap py-0 md:h-[42px] sm:h-auto text-[12px] text-cleanSlate inline-flex',
    input: () => 'm-0 p-0 react-select-input-wrapper h-[42px]',
    singleValue: () => 'bg-shadeStoneGray font-medium p-[4px] rounded-[4px]',
    multiValue: () => 'bg-shadeStoneGray font-medium p-[4px] rounded-[4px]',
    placeholder: () => 'text-sm',
    // z index here is needed to show over our StandardModal component
    // which by default is z index of 1000
    menuPortal: () => 'z-[1001]',
    multiValueLabel: () => 'text-[var(--color-cleanSlate)]',
    multiValueRemove: () => 'hover:bg-cleanSlate hover:text-white hover:rounded-full',
    option: (state) => `text-sm active:bg-nearlyWhiteGray text-[var(--color-cleanSlate)] ${state.isSelected && 'bg-nearlyWhiteGray text-black'} ${state.isFocused && !state.isSelected && 'bg-nearlyWhiteGray'}`,
});

export default {
    Styles,
    ReactSelectClassNames,
};
