import React from 'react';
import { components } from 'react-select';

import Arrow from './Arrow';

function DropdownIndicator(props) {
    const { selectProps } = props;
    const { menuIsOpen } = selectProps;
    return (
        <components.DropdownIndicator {...props}>
            <Arrow open={menuIsOpen} />
        </components.DropdownIndicator>
    );
}

export default DropdownIndicator;
