import React, { useRef } from 'react';
import Select from 'react-select';
import { Field } from 'formik';

import Label from '@/components/forms/label';
import Description from '@/components/forms/description';

import DropdownIndicator from './DropdownIndicator';
import stylesSheet from './Select.module.css';
import { ReactSelectClassNames } from './Shared';

function SingleSelect({
    outerClassName = undefined,
    labelClassName = 'text-cleanSlate text-[14px]',
    classNamePrefix = '',
    defaultOption = null,
    disabled = false,
    description = '',
    error = false,
    errorText = '',
    label = null,
    name,
    onChange = (_opt) => { },
    options,
    placeholder = null,
    isClearable = false,
    required = false,
}) {
    const selectRef = useRef(null);

    const handleOnChange = (option, form) => {
        form.setFieldValue(name, option ? option.value : '');
        onChange(option);
    };

    return (
        <div className={`${classNamePrefix}${outerClassName}`}>
            <Label
                className={labelClassName}
                htmlFor={name}
                label={label}
                required={required}
                onClick={() => {
                    if (selectRef && selectRef.current) {
                        selectRef.current.focus();
                    }
                }}
            />
            <Field
                disabled={disabled}
                name={name}
            >
                {
                    ({ field, form }) => (
                        <Select
                            // NOT to be confused with className singular!
                            // This is react-select specific:
                            // https://react-select.com/styles#the-classnames-prop
                            classNames={ReactSelectClassNames(error)}
                            name={name}
                            ref={selectRef}
                            menuPortalTarget={typeof (document) !== 'undefined' && document.body}
                            defaultValue={defaultOption}
                            value={options ? options.find((option) => option.value === field.value) : ''}
                            options={options}
                            isClearable={isClearable}
                            components={{ DropdownIndicator }}
                            isDisabled={disabled}
                            onChange={(option) => handleOnChange(option, form)}
                            placeholder={placeholder}
                            menuPlacement="auto"
                        />
                    )
                }
            </Field>
            <Description description={description} />
            {error && errorText.length > 0 && (
                <div className={stylesSheet.errorText}>
                    {errorText}
                </div>
            )}
        </div>
    );
}

export default SingleSelect;
