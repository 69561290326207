import React from 'react';

import styles from './Select.module.css';

function Arrow({
    open = false,
}) {
    const localStyles = {
        width: '1.5em',
        height: '1.5em',
        transform: open ? 'rotate(0.5turn)' : '',
    };
    return (
        <div style={localStyles} className={`bg-contain bg-center ${styles.arrow}`} />
    );
}

export default Arrow;
