import React, { MouseEventHandler, ReactElement } from 'react';

import styles from './Label.module.css';

type LabelProps = {
    readonly className?: string,
    readonly htmlFor: string,
    readonly label: string | ReactElement,
    readonly required?: boolean,
    readonly onClick?: MouseEventHandler,
};

function Label({
    className = '',
    required = false,
    htmlFor,
    label,
    onClick = (_event) => { },
}: LabelProps) {
    const finalClass = `${styles.label} ${className}`;
    return label
        && (
            <label
                className={finalClass}
                htmlFor={htmlFor}
                onClick={onClick}
                role="presentation"
            >
                {label}
                {required ? <span className={styles.requiredLabel}>*</span> : undefined}
            </label>
        );
}

export default Label;
